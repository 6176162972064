// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Header from "../../Components/Header";
import { useState } from "react";
import Form from "./Form";
import Headline from "./Headline";
import Swal from "sweetalert2";
import { Suspense } from "react";
import ReactLoading from "react-loading";

export default function Insurance() {
  const [strategies, setStrategies] = useState(["Strategy 1"]);

  const [companyID, setCompanyID] = useState(0);

  function addStrategy() {
    const number = strategies.length + 1;
    setStrategies([...strategies, "Strategy " + number]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // ongoing_charge OR ongoing_fixed_charge must be greater than zero
    // if introducer then split_from_advisor && 2nd_advisor required
    // if initial_fees then initial_charge && advisor_charge && fixed_charge required

    alert("Form will be submitted now!");
  };

  // confirm delete
  const areYouSure = (value) => {
    Swal.fire({
      title: "Are you sure you want to delete " + value + "?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStrategy(value);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deleteStrategy = (value) => {
    setStrategies((strategies) => {
      return strategies.filter((strategy) => strategy !== value);
    });
  };

  function BigSpinner() {
    return <h2>🌀 Loading...</h2>;
  }

  return (
    <>
      <Header />

      <Suspense fallback={<ReactLoading type={"spin"} color="green" />}>
        <section class="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div class="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-12">
            <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="p-8">
                <form onSubmit={handleSubmit}>
                  <Headline companyID={companyID} setCompanyID={setCompanyID} />

                  {strategies.map((item, key) => (
                    <div key={key} className="py-4">
                      <Form
                        companyID={companyID}
                        item={item}
                        areYouSure={areYouSure}
                      />
                    </div>
                  ))}

                  <div className="flex justify-end mb-4">
                    <button
                      type="button"
                      class="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                      onClick={() => {
                        addStrategy();
                      }}
                    >
                      Add another strategy +
                    </button>
                  </div>

                  <hr></hr>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
