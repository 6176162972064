// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Header from "../../Components/Header";
import { useState } from "react";
import Swal from "sweetalert2";
import { Tabs } from "flowbite-react";
import Details from "./Details";
import Advisors from "./Advisors";
import Introducers from "./Introducers";
import Strategies from "./Strategies";
import Portfolios from "./Portfolios";
import People from "./People";
import Users from "./Users";
import Companies from "./Companies";

export default function CompanyEdit() {
  const [show, setShow] = useState("details");
  const [detailsCompanyName, setDetailsCompanyName] = useState("");
  const [detailsCompanyLogo, setDetailsCompanyLogo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form will be submitted now!");
  };

  return (
    <>
      <Header />
      <section class="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
        <div class="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-40">
          <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="flex flex-row border-b-2 items-center">
              <img
                src={detailsCompanyLogo}
                alt="iMac Front Image"
                class="w-auto h-14 mr-3 mt-3 ml-4"
              ></img>
              <h2 className="text-xl font-semibold leading-7 text-gray-900 mt-2 p-3">
                {detailsCompanyName}
              </h2>
            </div>

            <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div class="px-4 divide-y dark:divide-gray-700">
                <div class="flex flex-col py-4 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4 md:py-0">
                  <ul class="flex-wrap hidden text-sm font-medium text-center text-gray-500 md:flex dark:text-gray-400">
                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("details")}
                        class="inline-flex items-center py-4 pr-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 mr-2 text-primary-600 dark:text-primary-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                          ></path>
                        </svg>
                        Details
                      </a>
                    </li>

                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("people")}
                        class="inline-flex items-center p-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        People
                      </a>
                    </li>
                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("strategies")}
                        class="inline-flex items-center p-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                            clip-rule="evenodd"
                          />
                          <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                        </svg>
                        Strategies
                      </a>
                    </li>
                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("portfolios")}
                        class="inline-flex items-center p-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Portfolios
                      </a>
                    </li>

                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("companies")}
                        class="inline-flex items-center p-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 mr-2 text-primary-600 dark:text-primary-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                          ></path>
                        </svg>
                        Company
                      </a>
                    </li>

                    <li class="mr-2">
                      <a
                        href="#"
                        onClick={() => setShow("users")}
                        class="inline-flex items-center p-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Users
                      </a>
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="flex items-center justify-center flex-shrink-0 px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    <svg
                      class="h-3.5 w-3.5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      ></path>
                    </svg>
                    Add new item
                  </button>
                </div>
              </div>
              {show === "details" && (
                <Details
                  setDetailsCompanyName={setDetailsCompanyName}
                  setDetailsCompanyLogo={setDetailsCompanyLogo}
                />
              )}
              {show === "strategies" && <Strategies />}
              {show === "portfolios" && <Portfolios />}
              {show === "companies" && <Companies />}
              {show === "people" && <People />}
              {show === "users" && <Users />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
