import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useState, React } from "react";
import { HomeIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function Navbar() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [currentTab, setCurrentTab] = useState(true);

  const navigation = [
    {
      name: "New Record",
      href: "/new-record",
      icon: HomeIcon,
      current: currentTab == "new-record" ? true : false,
    },
    {
      name: "Insurance",
      href: "/insurance",
      icon: HomeIcon,
      current: currentTab == "insurance" ? true : false,
    },
    {
      name: "Companies",
      href: "/companies",
      icon: PlusIcon,
      current: currentTab == "companies" ? true : false,
    },
    {
      name: "Providers",
      href: "/providers",
      icon: PlusIcon,
      current: currentTab == "providers" ? true : false,
    },
    {
      name: "Strategies",
      href: "/strategies",
      icon: PlusIcon,
      current: currentTab == "strategies" ? true : false,
    },
    {
      name: "Users",
      href: "/users",
      icon: PlusIcon,
      current: currentTab == "users" ? true : false,
    },
  ];

  return (
    <header>
      <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-900 antialiased">
        <div class="flex flex-wrap justify-between items-center">
          <div class="flex justify-start items-center">
            <img
              src="https://money-trove.com/wp-content/uploads/2019/06/trove-logo-standard-300.png"
              class="mr-12 h-12"
              alt="Finance Manager Logo"
            />

            <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
              <ul class="flex flex-col mt-4 space-x-6 font-medium lg:flex-row xl:space-x-8 lg:mt-0">
                {navigation.map((item) => (
                  <li>
                    <a
                      href={item.href}
                      className="block text-gray-700 hover:text-primary-700 dark:text-gray-400 dark:hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div class="mr-12 flex justify-between items-center lg:order-2">
            <a href="/">
              <div className="flex flex-row">
                <div className="block font-medium text-gray-700 hover:text-primary-700 dark:text-gray-400 dark:hover:text-white">
                  Log out
                </div>

                <img
                  class="w-auto h-4 mr-3 ml-3 mt-1"
                  src={require("../Assets/images/logout.png")}
                />
              </div>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
