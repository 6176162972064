import axios from "axios";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const endpoint = "https://fumapi.testwebspace.co.uk";

export const useGetRequest = (path) => {
  // const navigate = useNavigate();
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState([]);

  const auth = {
    headers: {
      Authorization: `Bearer dummytoken`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setStatus("fetching");
      const res = await axios.get(`${endpoint}/${path}`, auth);
      if (res) {
        setData(res.data);
      }
      setStatus("fetched");
    };

    fetchData();
  }, [path]);

  return { status, data };
};

export const sendPostRequest = async (path, params) => {
  // const navigate = useNavigate();

  const auth = {
    headers: {
      Authorization: `Bearer dummytoken`,
    },
  };

  const res = await axios.post(`${endpoint}/${path}`, params, auth);

  return res.data;
};

export const sendGetRequest = async (path, params) => {
  // const navigate = useNavigate();

  const auth = {
    headers: {
      Authorization: `Bearer dummytoken`,
    },
  };

  const res = await axios.get(`${endpoint}/${path}`, params, auth);

  return res.data;
};
