import { use, useState, useEffect } from "react";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import AdvisorDropdown from "./Components/AdvisorDropdown";
import IntroducerDropdown from "./Components/IntroducerDropdown";
import SecondAdvisorDropdown from "./Components/SecondAdvisorDropdown";

export default function Headline(props) {

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    props.setInputs((values) => ({ ...values, [name]: value }));
  };

  const { status: statusCompanies, data: dataCompanies } = useGetRequest(
    "companies",
    {}
  );

  if (statusCompanies == "fetched") {
    return (
      // <div className="flex flex-col mx-36 p-2 bg-white border-2 border-slate-100 rounded-xl">
      <div className="flex flex-col p-2 bg-white ">
        <div>
          <h2 class="mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Headline Information
          </h2>
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-1/5 p-2">
            <label
              for="company"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Regulated / Servicing Company
            </label>
            <select
              id="company"
              name="company"
              onChange={() => {
                props.setCompanyID(document.getElementById("company").value);
              }}
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value={0}></option>
              {dataCompanies.companies.map((company) => (
                <option value={company.companyID}>{company.companyName}</option>
              ))}
            </select>
          </div>

          <AdvisorDropdown
            label={"Ultimate Owner"}
            name={"ultimate_owner"}
            handleChange={handleChange}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="start_date"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              value={props.inputs.start_date || ""}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="product"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product
            </label>
            <select
              id="product"
              name="product"
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="Insurance">Insurance</option>
              <option value="Investment">Investment</option>
              <option value="Pension">Pension</option>
            </select>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="w-1/5 p-2">
            <label
              for="forename"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Client Forename
            </label>
            <input
              type="text"
              id="forename"
              name="forename"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="surname"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Client Surname
            </label>
            <input
              type="text"
              id="surname"
              name="surname"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="client_ref"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Bond Platform / Client Reference
            </label>
            <input
              type="text"
              id="client_ref"
              name="client_ref"
              onChange={handleChange}
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="bond_platform_no"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Bond Platform No
            </label>
            <input
              type="text"
              id="bond_platform_no"
              name="bond_platform_no"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="w-1/5 p-2">
            <label
              for="initial_total_value"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Initial Total Value
            </label>
            <input
              type="text"
              id="initial_total_value"
              name="initial_total_value"
              onChange={handleChange}
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="currency"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              defaultValue={"£"}
              onChange={() => {
                props.setCurrency(document.getElementById("currency").value);
              }}
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="1">£</option>
              <option value="2">$</option>
              <option value="3">€</option>
              <option value="4">CZK</option>
            </select>
          </div>

          {props.currency > 1 ? (
            <div className="w-1/5 p-2">
              <label
                for="exchange_rate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Exchange Rate
              </label>
              <input
                type="text"
                id="exchange_rate"
                name="exchange_rate"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          ) : null}
        </div>

        <div className="flex flex-row mt-2">
          <AdvisorDropdown
            label={"Advisor / Member Name"}
            name={"advisor"}
            handleChange={handleChange}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="agency"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Agency
            </label>
            <select
              id="agency"
              name="agency"
              onChange={handleChange}
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value=""></option>
              <option value="AI">AI</option>
              <option value="AFP">AFP</option>
            </select>
          </div>
        </div>

        <div className="flex flex-row mt-2">
          <IntroducerDropdown
            label={"Introducer"}
            name={"introducer"}
            handleChange={handleChange}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="introducer_split_from_advisor"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Introducer Split From Advisor
            </label>
            <input
              type="text"
              id="introducer_split_from_advisor"
              name="introducer_split_from_advisor"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="second_advisor_split_from_advisor"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              2nd Advisor Split From Advisor
            </label>
            <input
              type="text"
              id="second_advisor_split_from_advisor"
              name="second_advisor_split_from_advisor"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <SecondAdvisorDropdown
            label={"2nd second_advisor"}
            name={"second_advisor"}
            companyID={props.companyID}
            handleChange={handleChange}
          />

          <div className="w-1/5 p-2">
            <label
              for="applied_to"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Applied To
            </label>
            <select
              id="applied_to"
              name="applied_to"
              onChange={handleChange}
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value=""></option>
              <option value="Whole Fee">Whole Fee</option>
              <option value="Advisor Fee Only">Advisor Fee Only</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}
