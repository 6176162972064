import { useState } from "react";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import LeaderDropdown from "./Components/LeaderDropdown";

export default function Form(props) {
  const [initialFees, setInitialFees] = useState(false);
  const [currencyStrategy, setCurrencyStrategy] = useState("£");
  const [rebate, setRebate] = useState(false);

  const [providerID, setProviderID] = useState(0);

  const { status: statusPortfolios, data: dataPortfolios } = useGetRequest(
    "portfolios/" + props.companyID,
    {}
  );

  const { status: statusStrategies, data: dataStrategies } = useGetRequest(
    "strategies/" + props.companyID,
    {}
  );

  const { status: statusProviders, data: dataProviders } = useGetRequest(
    "providers",
    {}
  );

  if (
    statusPortfolios == "fetched" &&
    statusStrategies == "fetched" &&
    statusProviders == "fetched"
  ) {
    return (
      <div className="p-2 border-2 border-slate-100 rounded-xl md:max-xl:flex lg:w-full">
        <div>
          <div className="flex flex-row justify-between">
            <div>
              <h2 class="pl-2 mb-2 text-xl font-bold text-gray-900 dark:text-white">
                {props.item}
              </h2>
            </div>
            {props.item !== "Strategy 1" ? (
              <div>
                <h2
                  className="text-xs font-semibold leading-7 text-red-600 px-2 hover:cursor-pointer hover:text-red-700"
                  onClick={() => {
                    props.areYouSure(props.item);
                  }}
                >
                  Delete strategy
                </h2>
              </div>
            ) : null}
          </div>

          <h5 class="px-2 block mb-2 text-sm font-medium text-gray-800 dark:text-white">
            Investment Information
          </h5>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="policy_number"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Policy Number
              </label>
              <input
                type="text"
                id="policy_number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="trustee"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Trustee
              </label>
              <input
                type="text"
                id="trustee"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="trustee_no"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Trustee No
              </label>
              <input
                type="text"
                id="trustee_no"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 1
              </label>
              <input
                type="text"
                id="sub_policy_number_1"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 2
              </label>
              <input
                type="text"
                id="sub_policy_number_2"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_3"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 3
              </label>
              <input
                type="text"
                id="sub_policy_number_3"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_4"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 4
              </label>
              <input
                type="text"
                id="sub_policy_number_4"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <LeaderDropdown
              label={"Team Leader"}
              name={"team_leader"}
              companyID={props.companyID}
            />

            <div className="w-1/5 p-2">
              <label
                for="provider"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Provider
              </label>
              <select
                id="provider"
                name="provider"
                onChange={() => {
                  setProviderID(document.getElementById("provider").value);
                }}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value={0}></option>
                {dataProviders.providers.map((provider) => (
                  <option value={provider.providerID}>{provider.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="rebate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Will We Need To Rebate?
              </label>
              <input
                type="checkbox"
                id="rebate"
                checked={rebate}
                onChange={() => setRebate(!rebate)}
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className="align-center h-4"
              />
            </div>

            {rebate === true ? (
              <div className="w-1/5 p-2">
                <label
                  for="rebate_percentage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  % Required To Rebate
                </label>
                <input
                  type="text"
                  id="rebate_percentage"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            ) : null}
          </div>

          <h5 class="px-2 block mb-2 mt-4 text-sm font-medium text-gray-800 dark:text-white">
            Commissions & Fees
          </h5>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="ongoing_charge"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ongoing % Charge
              </label>
              <input
                type="text"
                id="ongoing_charge"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="ongoing_fixed_charge"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ongoing Fixed Charge
              </label>
              <input
                type="text"
                id="ongoing_fixed_charge"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="unique_identifier"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Unique Identifier
              </label>
              <input
                type="text"
                id="unique_identifier"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          {/* <div className="mt-5 flex flex-row mt-2 justify-start text-sm leading-6 text-gray-600 px-2 underline">
          Additional Options
        </div> */}

          <h5 class="px-2 block mb-2 mt-4 text-sm font-medium text-gray-800 dark:text-white">
            Initial Fee Options
          </h5>
          <div className="flex flex-row mt-2">
            {/* <div className="w-1/5 p-2">
            <label
              htmlFor="initial_fees"
              className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
            >
              Are there initial fees as well?
            </label>
            <select
              id="initial_fees"
              name="initial_fees"
              onChange={() => {
                // setInitialFees(document.getElementById("initialFees").value);
                setInitialFees(!initialFees);
              }}
              defaultValue={"No"}
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div> */}

            <div className="w-1/5 p-2">
              <label
                for="initial_fees"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Are there initial fees as well?
              </label>
              <input
                type="checkbox"
                id="initial_fees"
                checked={initialFees}
                onChange={() => setInitialFees(!initialFees)}
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className="align-center h-4"
              />
            </div>

            {initialFees === true ? (
              <>
                <div className="w-1/5 p-2">
                  <label
                    for="initial_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Initial Charge (%)
                  </label>
                  <input
                    type="text"
                    id="initial_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="w-1/5 p-2">
                  <label
                    for="advisor_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Split / Adviser Charge
                  </label>
                  <input
                    type="text"
                    id="advisor_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="w-1/5 p-2">
                  <label
                    for="fixed_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Initial Fixed Charge
                  </label>
                  <input
                    type="text"
                    id="fixed_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
