import { useState } from "react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import CountryDropdown from "../../Components/CountryDropdown";
import { useNavigate } from "react-router-dom";
import { sendPostRequest } from "../../Client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte

export default function Add() {
  const [compliance, setCompliance] = useState();
  const [finance, setFinance] = useState();
  const [inputs, setInputs] = useState({});

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Company added");
  };

  // adding companies
  const companyAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return companyAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
  });

  const countries = CountryDropdown();

  return (
    <div className="flex flex-col mx-36 p-2 bg-gray-100">
      <div>
        <h2 className="text-xl font-semibold leading-7 text-gray-900 px-2">
          Company Information
        </h2>
      </div>
      <div className="flex flex-row">
        <div className="w-1/5 p-2">
          <label
            htmlFor="companyName"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Company Name
          </label>
          <input
            type="input"
            id="companyName"
            name="companyName"
            onChange={handleChange}
            value={inputs.companyName || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        {/* <div className="w-1/5 p-2 mt-4">
          <label
            htmlFor="logo"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Logo
          </label>
          <div className="mt-2 flex items-center gap-x-3">
            <UserCircleIcon
              className="h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <button
              type="file"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Change
            </button>
          </div>
        </div> */}
      </div>

      <div className="flex flex-row">
        <div className="w-1/5 p-2">
          <label
            htmlFor="id_number"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            ID Number
          </label>
          <input
            type="input"
            id="id_number"
            name="id_number"
            onChange={handleChange}
            value={inputs.id_number || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>

        <div className="w-1/5 p-2">
          <label
            htmlFor="vatNumber"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            VAT Number
          </label>
          <input
            type="input"
            id="vatNumber"
            name="vatNumber"
            onChange={handleChange}
            value={inputs.vatNumber || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>

        <div className="w-1/5 p-2">
          <label
            htmlFor="bankConnection"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Bank Connection
          </label>
          <input
            type="input"
            id="bankConnection"
            name="bankConnection"
            onChange={handleChange}
            value={inputs.bankConnection || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>

        <div className="w-1/5 p-2">
          <label
            htmlFor="bankDetails"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Bank Details
          </label>
          <input
            type="input"
            id="bankDetails"
            name="bankDetails"
            onChange={handleChange}
            value={inputs.bankDetails || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="w-1/5 p-2">
          <label
            htmlFor="address1"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Address Line 1
          </label>
          <input
            type="input"
            id="address1"
            name="address1"
            onChange={handleChange}
            value={inputs.address1 || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div className="w-1/5 p-2">
          <label
            htmlFor="address2"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Address Line 2
          </label>
          <input
            type="input"
            id="address2"
            name="address2"
            onChange={handleChange}
            value={inputs.address2 || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div className="w-1/5 p-2">
          <label
            htmlFor="town"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Town
          </label>
          <input
            type="input"
            id="town"
            name="town"
            onChange={handleChange}
            value={inputs.town || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="w-1/5 p-2">
          <label
            htmlFor="county"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            County
          </label>
          <input
            type="input"
            id="county"
            name="county"
            onChange={handleChange}
            value={inputs.county || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div className="w-1/5 p-2">
          <label
            htmlFor="postcode"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Postcode
          </label>
          <input
            type="input"
            id="postcode"
            name="postcode"
            onChange={handleChange}
            value={inputs.postcode || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div className="w-1/5 p-2">
          <label
            htmlFor="country"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Country
          </label>
          <select
            id="country"
            name="country"
            onChange={handleChange}
            value={inputs.country || ""}
            required
            className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value=""></option>
            {countries.map((country) => (
              <option value={country.code}>{country.name}</option>
            ))}
          </select>
        </div>
      </div>

      {/* <div className="mt-5 flex flex-row mt-2 justify-start text-sm leading-6 text-gray-600 px-2 underline">
        Areas of Management
      </div>

      <div className="flex flex-row mt-2">
        <div className="w-1/5 p-2">
          <label
            htmlFor="compliance"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Compliance
          </label>
          <input
            type="checkbox"
            id="compliance"
            checked={compliance}
            onChange={() => setCompliance(!compliance)}
            className="align-center h-4"
          />
        </div>

         <div className="w-1/5 p-2">
          <label
            htmlFor="finance"
            className="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
          >
            Finance
          </label>
          <input
            type="checkbox"
            id="finance"
            checked={finance}
            onChange={() => setFinance(!finance)}
            className="align-center h-4"
          />
        </div>
      </div> */}

      <a
        className="w-[20%] inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
        href="#"
        onClick={async () => {
          try {
            await doAddMutation({
              path: "companies/add",
              body: {
                companyName: inputs.companyName,
                ID: inputs.id_number,
                vatNumber: inputs.vatNumber,
                bankConnection: inputs.bankConnection,
                bankDetails: inputs.bankDetails,
                address1: inputs.address1,
                address2: inputs.address2,
                town: inputs.town,
                county: inputs.county,
                postcode: inputs.postcode,
                country: inputs.country,
              },
            });
            navigate(`/companies`, { replace: true, state: { added: 1 } });
          } catch (e) {
            console.log(e);
          }
        }}
      >
        Add Company
      </a>
    </div>
  );
}
