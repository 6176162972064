import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useState, React } from "react";
import { HomeIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function Navbar() {
  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(" ");
  // }

  // const [currentTab, setCurrentTab] = useState(true);

  // const navigation = [
  //   {
  //     name: "New Record",
  //     href: "/new-record",
  //     icon: HomeIcon,
  //     current: currentTab == "new-record" ? true : false,
  //   },
  //   {
  //     name: "Companies",
  //     href: "/companies",
  //     icon: PlusIcon,
  //     current: currentTab == "companies" ? true : false,
  //   },
  //   {
  //     name: "Users",
  //     href: "/users",
  //     icon: PlusIcon,
  //     current: currentTab == "users" ? true : false,
  //   },
  // ];

  // return (
  //   <Disclosure as="nav" className="bg-white shadow">
  //     {({ open }) => (
  //       <>
  //         <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
  //           <div className="relative flex h-16 justify-between">
  //             <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
  //               <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
  //                 <span className="absolute -inset-0.5" />
  //                 <span className="sr-only">Open main menu</span>
  //               </Disclosure.Button>
  //             </div>{" "}
  //             <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
  //               <div className="flex flex-shrink-0 items-center">
  //                 <img
  //                   className="h-12 w-auto"
  //                   src="https://money-trove.com/wp-content/uploads/2019/06/trove-logo-standard-300.png"
  //                   alt="Finance Manager"
  //                 />
  //               </div>

  //               {navigation.map((item) => (
  //                 <div className="flex items-center px-4" key={item.name}>
  //                   <a
  //                     href={item.href}
  //                     onClick={() => {
  //                       setCurrentTab(item.name);
  //                     }}
  //                     className={classNames(
  //                       item.current
  //                         ? "bg-gray-800 text-white"
  //                         : "text-gray-400 hover:text-white hover:bg-gray-800",
  //                       "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
  //                     )}
  //                   >
  //                     {/* <item.icon
  //                       className="h-6 w-6 shrink-0"
  //                       aria-hidden="true"
  //                     /> */}
  //                     {item.name}
  //                   </a>
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     )}
  //   </Disclosure>
  // );
}
