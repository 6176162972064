// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Navbar from "../../Components/Navbar";
import { useState } from "react";
import Swal from "sweetalert2";
import CompaniesAdd from "./add";

export default function CompanyAdd() {
  const [strategies, setStrategies] = useState(["Strategy 1"]);

  function addStrategy() {
    const number = strategies.length + 1;
    setStrategies([...strategies, "Strategy " + number]);
  }

  return (
    <>
      <Navbar />
      <div className="p-8">
        {/* <form onSubmit={handleSubmit}> */}
        <CompaniesAdd />

        <hr className="mt-4"></hr>

        {/* <div className="mt-4 mx-36">
            <button
              type="submit"
              // onClick={() => handleSubmit()}
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
            >
              Create
            </button>
          </div> */}
        {/* </form> */}
      </div>
    </>
  );
}
