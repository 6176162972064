import { sendGetRequest } from "../../../Client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Button, Table, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Strategies() {
  const navigate = useNavigate();

  const { companyID } = useParams();

  const queryClient = useQueryClient();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  // loading strategies
  const { data: strategies, isLoading } = useQuery({
    queryFn: () => sendGetRequest("company/strategies/" + companyID, {}),
    queryKey: ["strategies"],
  });

  const strategyNotyf = () => {
    notyf.success({ message: "Strategies Updated", background: "#de2016" });
  };

  const activate = async (strategyID) => {
    const response = await sendGetRequest(
      "strategy/activate/" + strategyID + "/" + companyID,
      {}
    );

    queryClient.invalidateQueries({ queryKey: ["strategies"] });

    strategyNotyf();
  };

  const deactivate = async (strategyID) => {
    const response = await sendGetRequest(
      "strategy/deactivate/" + strategyID + "/" + companyID,
      {}
    );

    queryClient.invalidateQueries({ queryKey: ["strategies"] });

    strategyNotyf();
  };

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-col p-2 border-2 border-slate-100 rounded-xl">
          <div className="flex flex-row justify-between items-center px-2 mt-2">
            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900 px-2 mt-2">
                Strategies
              </h2>
            </div>
          </div>

          <div className="mt-8 flow-root ml-4 mr-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {strategies.strategies.length === 0 ? (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Strategy Name</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="w-1/4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          No Strategies found
                        </Table.Cell>

                        <Table.Cell className="w-3/4"></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Strategy Name</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {strategies.strategies.map((strategy) => (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="w-11/12 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {strategy.strategyName}
                          </Table.Cell>

                          {strategy.active == 1 ? (
                            <Table.Cell className="w-1/12">
                              <a
                                onClick={() => {
                                  deactivate(strategy.strategyID);
                                }}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Button color="failure" size="sm">
                                  Deactivate
                                </Button>
                              </a>
                            </Table.Cell>
                          ) : (
                            <Table.Cell className="w-1/12">
                              <a
                                onClick={() => {
                                  activate(strategy.strategyID);
                                }}
                                className="text-green-600 hover:text-green-900"
                              >
                                <Button color="success" size="sm">
                                  Activate
                                </Button>
                              </a>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
