import { sendGetRequest, sendPostRequest } from "../../../Client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Button, Table, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Portfolios() {
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});

  const navigate = useNavigate();

  const { companyID } = useParams();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const queryClient = useQueryClient();

  // loading portfolios
  const { data: portfolios, isLoading } = useQuery({
    queryFn: () => sendGetRequest("portfolios/" + companyID, {}),
    queryKey: ["portfolios"],
  });

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Portfolio deleted", background: "#de2016" });
  };

  // deleting strategy
  const portfolioDelete = async (id) => {
    const res = await sendPostRequest("portfolio/delete", {
      portfolioID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return portfolioDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["portfolios"] });
      deletedNotyf();
    },
  });

  const addedNotyf = () => {
    notyf.success("Portfolio added");
  };

  // adding portfolio
  const portfolioAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return portfolioAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["portfolios"] });
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-col p-2 border-2 border-slate-100 rounded-xl">
          <div className="flex flex-row justify-between items-center px-2 mt-2">
            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900 px-2 mt-2">
                Portfolios
              </h2>
            </div>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800"
            >
              Add Portfolio
            </button>
          </div>

          <div className="mt-8 flow-root ml-4 mr-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {portfolios.portfolios.length === 0 ? (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Portfolio Name</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="w-1/4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          No Portfolios found
                        </Table.Cell>

                        <Table.Cell className="w-3/4"></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Portfolio Name</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {portfolios.portfolios.map((portfolio) => (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="w-11/12 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {portfolio.portfolioName}
                          </Table.Cell>

                          <Table.Cell className="w-1/12 justify-end">
                            <a
                              onClick={() => {
                                if (areYouSure(portfolio.portfolioID)) {
                                  portfolioDelete(portfolio.portfolioID);
                                }
                              }}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Button color="failure" size="sm">
                                Delete
                              </Button>
                            </a>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={openModal} size="xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 class="text-lg font-bold text-gray-900 dark:text-white">
                Add Portfolio
              </h2>
              <div className="flex flex-row">
                <div className="flex flex-row w-full">
                  <div className="w-2/3 p-2">
                    <label
                      for="portfolioName"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Portfolio Name
                    </label>
                    <input
                      type="input"
                      id="portfolioName"
                      name="portfolioName"
                      onChange={handleChange}
                      value={inputs.portfolioName || ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="w-full ml-2">
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      await doAddMutation({
                        path: "portfolio/add",
                        body: {
                          companyID: companyID,
                          portfolioName: inputs.portfolioName,
                        },
                      });

                      setOpenModal(false);
                      setInputs({});
                      navigate(`/companies/edit/${companyID}`, {
                        replace: true,
                        state: { added: 1 },
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  class="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                >
                  Add Portfolio
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
