import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import Insurance from "./Pages/Insurance";
import Companies from "./Pages/Companies/index.js";
import CompaniesManage from "./Pages/CompaniesManage";
import CompaniesAdd from "./Pages/CompaniesAdd";
import CompaniesEdit from "./Pages/CompaniesEdit";
import Users from "./Pages/Users";
import Providers from "./Pages/Providers";
import Strategies from "./Pages/Strategies";
import Login from "./Pages/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/new-record",
    element: <Home />,
  },
  {
    path: "/insurance",
    element: <Insurance />,
  },
  {
    path: "/companies",
    element: <Companies />,
  },
  {
    path: "/companies/add",
    element: <CompaniesAdd />,
  },
  {
    path: "/companies/edit/:companyID",
    element: <CompaniesEdit />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/providers",
    element: <Providers />,
  },
  {
    path: "/strategies",
    element: <Strategies />,
  }
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
