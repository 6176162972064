import { useState } from "react";

export default function Bound(props) {
  const [bounds, setBounds] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    props.setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div className="flex flex-row">
      <div className="w-1/4 p-1">
        <label
          for="lowerBound"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Lower Tier
        </label>
        <input
          type="input"
          id="lowerBound"
          name="lowerBound"
          onChange={(e) => {
            props.setBoundData({
              ...props.boundData,
              [props.id + "-lowerBound"]: e.target.value,
            });
          }}
          // value={props.inputs.lowerBound || ""}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
      <div className="w-1/4 p-1">
        <label
          for="upperBound"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Upper Tier
        </label>
        <input
          type="input"
          id="upperBound"
          name="upperBound"
          onChange={(e) => {
            props.setBoundData({
              ...props.boundData,
              [props.id + "-upperBound"]: e.target.value,
            });
          }}
          // value={props.inputs.upperBound || ""}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
      <div className="w-1/4 p-1">
        <label
          for="percentage"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Percentage
        </label>
        <input
          type="input"
          id="percentage"
          name="percentage"
          onChange={(e) => {
            props.setBoundData({
              ...props.boundData,
              [props.id + "-percentage"]: e.target.value,
            });
          }}
          // value={props.inputs.percentage || ""}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>

      {props.item !== "Bound 1" ? (
        <div className="w-1/4 p-1">
          <h2
            className="mt-10 text-xs font-semibold leading-7 text-red-600 px-2 hover:cursor-pointer hover:text-red-700"
            onClick={() => {
              props.areYouSureBound(props.item);
            }}
          >
            Delete bound
          </h2>
        </div>
      ) : null}
    </div>
  );
}
