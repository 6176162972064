import { sendGetRequest, sendPostRequest } from "../../../Client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Button, Table, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CountryDropdown from "../../../Components/CountryDropdown";

export default function Companies() {
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});

  const [regulated, setRegulated] = useState(false);
  const [introducer, setIntroducer] = useState(false);
  const [teamLeader, setTeamLeader] = useState(false);
  const [branch, setBranch] = useState(false);

  const navigate = useNavigate();

  const countries = CountryDropdown();

  const { companyID } = useParams();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const queryClient = useQueryClient();

  // loading companies
  const { data: companies, isLoading } = useQuery({
    queryFn: () => sendGetRequest("companies/linked/" + companyID, {}),
    queryKey: ["companies"],
  });

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Company deleted", background: "#de2016" });
  };

  // deleting strategy
  const companyDelete = async (id) => {
    const res = await sendPostRequest("companies/linked/delete", {
      companyID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return companyDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      deletedNotyf();
    },
  });

  const addedNotyf = () => {
    notyf.success("Company added");
  };

  // adding company
  const companyAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return companyAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-col p-2 border-2 border-slate-100 rounded-xl">
          <div className="flex flex-row justify-between items-center px-2 mt-2">
            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900 px-2 mt-2">
                Companies
              </h2>
            </div>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800"
            >
              Add Company
            </button>
          </div>

          <div className="mt-8 flow-root ml-4 mr-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {companies.companies.length === 0 ? (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Company Name</Table.HeadCell>
                      <Table.HeadCell>Type</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="w-1/4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          No Companies found
                        </Table.Cell>

                        <Table.Cell className="w-3/4"></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Company Name</Table.HeadCell>
                      <Table.HeadCell>Type</Table.HeadCell>

                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {companies.companies.map((company) => (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="w-5/12 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {company.companyName}
                          </Table.Cell>

                          <Table.Cell className="w-5/12 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {company.introducer == 1 ? (
                              <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Introducer
                              </span>
                            ) : null}
                            {company.regulated == 1 ? (
                              <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                Regulated
                              </span>
                            ) : null}
                            {company.branch == 1 ? (
                              <span className="inline-flex items-center rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
                                Branch
                              </span>
                            ) : null}
                            {company.teamLeader == 1 ? (
                              <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Team Leader
                              </span>
                            ) : null}
                          </Table.Cell>

                          <Table.Cell className="w-2/12 justify-end">
                            <a
                              onClick={() => {
                                if (areYouSure(company.companyID)) {
                                  companyDelete(company.companyID);
                                }
                              }}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Button color="failure" size="sm">
                                Delete
                              </Button>
                            </a>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <h2 class="p-2 text-xl font-bold text-gray-900 dark:text-white">
              Add Company
            </h2>

            <div className="flex flex-row mt-2">
              <div className="w-1/6 p-1">
                <label
                  for="introducer"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Introducer
                </label>
                <input
                  type="checkbox"
                  id="introducer"
                  checked={introducer}
                  onChange={() => setIntroducer(!introducer)}
                  className="align-center h-4"
                />
              </div>

              <div className="w-1/6 p-1">
                <label
                  for="regulated"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Regulated
                </label>
                <input
                  type="checkbox"
                  id="regulated"
                  checked={regulated}
                  onChange={() => setRegulated(!regulated)}
                  className="align-center h-4"
                />
              </div>

              <div className="w-1/6 p-1">
                <label
                  for="branch"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Branch
                </label>
                <input
                  type="checkbox"
                  id="branch"
                  checked={branch}
                  onChange={() => setBranch(!branch)}
                  className="align-center h-4"
                />
              </div>

              <div className="w-1/6 p-1">
                <label
                  for="teamLeader"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Team Leader
                </label>
                <input
                  type="checkbox"
                  id="teamLeader"
                  checked={teamLeader}
                  onChange={() => setTeamLeader(!teamLeader)}
                  className="align-center h-4"
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="companyName"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Company Name
                </label>
                <input
                  type="input"
                  id="companyName"
                  name="companyName"
                  onChange={handleChange}
                  value={inputs.companyName || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="id_number"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  ID Number
                </label>
                <input
                  type="input"
                  id="id_number"
                  name="id_number"
                  onChange={handleChange}
                  value={inputs.id_number || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="vatNumber"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  VAT Number
                </label>
                <input
                  type="input"
                  id="vatNumber"
                  name="vatNumber"
                  onChange={handleChange}
                  value={inputs.vatNumber || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="bankConnection"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bank Connection
                </label>
                <input
                  type="input"
                  id="bankConnection"
                  name="bankConnection"
                  onChange={handleChange}
                  value={inputs.bankConnection || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="bankDetails"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bank Details
                </label>
                <input
                  type="input"
                  id="bankDetails"
                  name="bankDetails"
                  onChange={handleChange}
                  value={inputs.bankDetails || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="address1"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address Line 1
                </label>
                <input
                  type="input"
                  id="address1"
                  name="address1"
                  onChange={handleChange}
                  value={inputs.address1 || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="address2"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address Line 2
                </label>
                <input
                  type="input"
                  id="address2"
                  name="address2"
                  onChange={handleChange}
                  value={inputs.address2 || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="town"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Town
                </label>
                <input
                  type="input"
                  id="town"
                  name="town"
                  onChange={handleChange}
                  value={inputs.town || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="county"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  County
                </label>
                <input
                  type="input"
                  id="county"
                  name="county"
                  onChange={handleChange}
                  value={inputs.county || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="postcode"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Postcode
                </label>
                <input
                  type="input"
                  id="postcode"
                  name="postcode"
                  onChange={handleChange}
                  value={inputs.postcode || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="country"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  onChange={handleChange}
                  value={inputs.country || ""}
                  required
                  className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value=""></option>
                  {countries.map((country) => (
                    <option value={country.code}>{country.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <a
              className="mt-4 w-[20%] inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 "
              href="#"
              onClick={async () => {
                try {
                  await doAddMutation({
                    path: "companies/linked/add",
                    body: {
                      companyName: inputs.companyName,
                      linkedCompanyID: companyID,
                      ID: inputs.id_number,
                      vatNumber: inputs.vatNumber,
                      bankConnection: inputs.bankConnection,
                      bankDetails: inputs.bankDetails,
                      address1: inputs.address1,
                      address2: inputs.address2,
                      town: inputs.town,
                      county: inputs.county,
                      postcode: inputs.postcode,
                      country: inputs.country,
                      introducer: introducer ? 1 : 0,
                      regulated: regulated ? 1 : 0,
                      teamLeader: teamLeader ? 1 : 0,
                      branch: branch ? 1 : 0,
                    },
                  });
                  setOpenModal(false);
                  setInputs({});
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              Add Company
            </a>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
